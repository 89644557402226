// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-demokratiska-samtal-index-jsx": () => import("./../../../src/pages/demokratiska-samtal/index.jsx" /* webpackChunkName: "component---src-pages-demokratiska-samtal-index-jsx" */),
  "component---src-pages-demokratiska-samtal-skapa-demokratiska-samtal-handledning-index-jsx": () => import("./../../../src/pages/demokratiska-samtal/skapa-demokratiska-samtal/handledning/index.jsx" /* webpackChunkName: "component---src-pages-demokratiska-samtal-skapa-demokratiska-samtal-handledning-index-jsx" */),
  "component---src-pages-demokratiska-samtal-skapa-demokratiska-samtal-index-jsx": () => import("./../../../src/pages/demokratiska-samtal/skapa-demokratiska-samtal/index.jsx" /* webpackChunkName: "component---src-pages-demokratiska-samtal-skapa-demokratiska-samtal-index-jsx" */),
  "component---src-pages-demokratiska-samtal-skapa-demokratiska-samtal-kollage-index-jsx": () => import("./../../../src/pages/demokratiska-samtal/skapa-demokratiska-samtal/kollage/index.jsx" /* webpackChunkName: "component---src-pages-demokratiska-samtal-skapa-demokratiska-samtal-kollage-index-jsx" */),
  "component---src-pages-demokratiska-samtal-skapa-demokratiska-samtal-kollage-list-index-jsx": () => import("./../../../src/pages/demokratiska-samtal/skapa-demokratiska-samtal/kollage-list/index.jsx" /* webpackChunkName: "component---src-pages-demokratiska-samtal-skapa-demokratiska-samtal-kollage-list-index-jsx" */),
  "component---src-pages-demokratiska-samtal-vad-ar-rattvisa-handledning-index-jsx": () => import("./../../../src/pages/demokratiska-samtal/vad-ar-rattvisa/handledning/index.jsx" /* webpackChunkName: "component---src-pages-demokratiska-samtal-vad-ar-rattvisa-handledning-index-jsx" */),
  "component---src-pages-demokratiska-samtal-vad-ar-rattvisa-index-jsx": () => import("./../../../src/pages/demokratiska-samtal/vad-ar-rattvisa/index.jsx" /* webpackChunkName: "component---src-pages-demokratiska-samtal-vad-ar-rattvisa-index-jsx" */),
  "component---src-pages-demokratiska-samtal-vad-ar-rattvisa-pizza-index-jsx": () => import("./../../../src/pages/demokratiska-samtal/vad-ar-rattvisa/pizza/index.jsx" /* webpackChunkName: "component---src-pages-demokratiska-samtal-vad-ar-rattvisa-pizza-index-jsx" */),
  "component---src-pages-historiska-perspektiv-index-jsx": () => import("./../../../src/pages/historiska-perspektiv/index.jsx" /* webpackChunkName: "component---src-pages-historiska-perspektiv-index-jsx" */),
  "component---src-pages-historiska-perspektiv-tidslinjen-oversikt-handledning-index-jsx": () => import("./../../../src/pages/historiska-perspektiv/tidslinjen-oversikt/handledning/index.jsx" /* webpackChunkName: "component---src-pages-historiska-perspektiv-tidslinjen-oversikt-handledning-index-jsx" */),
  "component---src-pages-historiska-perspektiv-tidslinjen-oversikt-index-jsx": () => import("./../../../src/pages/historiska-perspektiv/tidslinjen-oversikt/index.jsx" /* webpackChunkName: "component---src-pages-historiska-perspektiv-tidslinjen-oversikt-index-jsx" */),
  "component---src-pages-historiska-perspektiv-tidslinjen-oversikt-tidslinjen-index-jsx": () => import("./../../../src/pages/historiska-perspektiv/tidslinjen-oversikt/tidslinjen/index.jsx" /* webpackChunkName: "component---src-pages-historiska-perspektiv-tidslinjen-oversikt-tidslinjen-index-jsx" */),
  "component---src-pages-historiska-perspektiv-utan-demokratiska-rattigheter-ester-henning-index-jsx": () => import("./../../../src/pages/historiska-perspektiv/utan-demokratiska-rattigheter/ester-henning/index.jsx" /* webpackChunkName: "component---src-pages-historiska-perspektiv-utan-demokratiska-rattigheter-ester-henning-index-jsx" */),
  "component---src-pages-historiska-perspektiv-utan-demokratiska-rattigheter-handledning-index-jsx": () => import("./../../../src/pages/historiska-perspektiv/utan-demokratiska-rattigheter/handledning/index.jsx" /* webpackChunkName: "component---src-pages-historiska-perspektiv-utan-demokratiska-rattigheter-handledning-index-jsx" */),
  "component---src-pages-historiska-perspektiv-utan-demokratiska-rattigheter-index-jsx": () => import("./../../../src/pages/historiska-perspektiv/utan-demokratiska-rattigheter/index.jsx" /* webpackChunkName: "component---src-pages-historiska-perspektiv-utan-demokratiska-rattigheter-index-jsx" */),
  "component---src-pages-historiska-perspektiv-utan-demokratiska-rattigheter-johan-dimitri-taikon-index-jsx": () => import("./../../../src/pages/historiska-perspektiv/utan-demokratiska-rattigheter/johan-dimitri-taikon/index.jsx" /* webpackChunkName: "component---src-pages-historiska-perspektiv-utan-demokratiska-rattigheter-johan-dimitri-taikon-index-jsx" */),
  "component---src-pages-historiska-perspektiv-utan-demokratiska-rattigheter-johan-dimitri-taikon-stockholmstidningen-29-augusti-1943-index-jsx": () => import("./../../../src/pages/historiska-perspektiv/utan-demokratiska-rattigheter/johan-dimitri-taikon/stockholmstidningen-29-augusti-1943/index.jsx" /* webpackChunkName: "component---src-pages-historiska-perspektiv-utan-demokratiska-rattigheter-johan-dimitri-taikon-stockholmstidningen-29-augusti-1943-index-jsx" */),
  "component---src-pages-historiska-perspektiv-utan-demokratiska-rattigheter-maj-britt-johansson-index-jsx": () => import("./../../../src/pages/historiska-perspektiv/utan-demokratiska-rattigheter/maj-britt-johansson/index.jsx" /* webpackChunkName: "component---src-pages-historiska-perspektiv-utan-demokratiska-rattigheter-maj-britt-johansson-index-jsx" */),
  "component---src-pages-historiska-perspektiv-utan-demokratiska-rattigheter-maj-britt-johansson-maj-britts-brev-index-jsx": () => import("./../../../src/pages/historiska-perspektiv/utan-demokratiska-rattigheter/maj-britt-johansson/maj-britts-brev/index.jsx" /* webpackChunkName: "component---src-pages-historiska-perspektiv-utan-demokratiska-rattigheter-maj-britt-johansson-maj-britts-brev-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-om-demokrati-demokratiska-principer-handledning-index-jsx": () => import("./../../../src/pages/om-demokrati/demokratiska-principer/handledning/index.jsx" /* webpackChunkName: "component---src-pages-om-demokrati-demokratiska-principer-handledning-index-jsx" */),
  "component---src-pages-om-demokrati-demokratiska-principer-index-jsx": () => import("./../../../src/pages/om-demokrati/demokratiska-principer/index.jsx" /* webpackChunkName: "component---src-pages-om-demokrati-demokratiska-principer-index-jsx" */),
  "component---src-pages-om-demokrati-index-jsx": () => import("./../../../src/pages/om-demokrati/index.jsx" /* webpackChunkName: "component---src-pages-om-demokrati-index-jsx" */),
  "component---src-pages-om-demokrati-roster-om-demokrati-handledning-index-jsx": () => import("./../../../src/pages/om-demokrati/roster-om-demokrati/handledning/index.jsx" /* webpackChunkName: "component---src-pages-om-demokrati-roster-om-demokrati-handledning-index-jsx" */),
  "component---src-pages-om-demokrati-roster-om-demokrati-index-jsx": () => import("./../../../src/pages/om-demokrati/roster-om-demokrati/index.jsx" /* webpackChunkName: "component---src-pages-om-demokrati-roster-om-demokrati-index-jsx" */),
  "component---src-pages-om-klassrumsmaterialet-index-jsx": () => import("./../../../src/pages/om-klassrumsmaterialet/index.jsx" /* webpackChunkName: "component---src-pages-om-klassrumsmaterialet-index-jsx" */)
}

