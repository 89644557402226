module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"autoGenHomeLabel":"Start","crumbLabelUpdates":[{"pathname":"/om-demokrati/roster-om-demokrati","crumbLabel":"Röster om demokrati"},{"pathname":"/om-demokrati","crumbLabel":"Om demokrati"},{"pathname":"/om-demokrati/demokratiska-principer","crumbLabel":"Demokratiska principer"},{"pathname":"/om-demokrati/roster-om-demokrati/handledning","crumbLabel":"Handledning"},{"pathname":"/demokratiska-samtal","crumbLabel":"Demokratiska samtal"},{"pathname":"/demokratiska-samtal/skapa-demokratiska-samtal","crumbLabel":"Skapa demokratiska samtal"},{"pathname":"/demokratiska-samtal/vad-ar-rattvisa","crumbLabel":"Vad är rättvisa"},{"pathname":"/demokratiska-samtal/vad-ar-rattvisa/handledning","crumbLabel":"Handledning"},{"pathname":"/historiska-perspektiv","crumbLabel":"Historiska perspektiv på demokrati"},{"pathname":"/historiska-perspektiv/utan-demokratiska-rattigheter","crumbLabel":"Historiska berättelser"},{"pathname":"/historiska-perspektiv/utan-demokratiska-rattigheter/maj-britt-johansson","crumbLabel":"Maj-Britt Johansson"},{"pathname":"/historiska-perspektiv/utan-demokratiska-rattigheter/maj-britt-johansson","crumbLabel":"Maj-Britt Johansson"},{"pathname":"/historiska-perspektiv/utan-demokratiska-rattigheter/ester-henning","crumbLabel":"Ester Henning"},{"pathname":"/historiska-perspektiv/utan-demokratiska-rattigheter/johan-dimitri-taikon","crumbLabel":"Johan Dimitri Taikon"},{"pathname":"/historiska-perspektiv/utan-demokratiska-rattigheter/maj-britt-johansson/qr-kod","crumbLabel":"Länk och QR-KOD"},{"pathname":"/historiska-perspektiv/tidslinje","crumbLabel":"Tidslinje"},{"pathname":"/historiska-perspektiv/utan-demokratiska-rattigheter/handledning","crumbLabel":"Handledning"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://admin.demokratipagar.se/wp/graphql","verbose":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
